import { GatsbySeo } from 'gatsby-plugin-next-seo'
import TrocasDevolucoes from 'src/components/servicos/ServicesPage/TrocasDevolucoes'

function Page() {
  return (
    <>
      <GatsbySeo language="pt-br" title="Trocas e devoluções | Decathlon" />
      <TrocasDevolucoes />
    </>
  )
}

export default Page
